@import "./common/mixins";
@import "./common/variables";
@import "./common/global";

.main {
    @include flexbox();
    @include align-items(flex-end);
    @include justify-content(center);
    width: 100%;
    height: calc(100vh - 84px);
    background-size: cover;
    background-image: url("../img/index_img.jpg");

    .text-wrapper {
        @include flexbox();
        @include align-items(center);
        @include flex-direction(column);
        width: 100%;
        text-align: center;
        padding: 0 0 100px;

        button {
            margin: 30px 0;
        }
    }
}

.site-points {
    @include flexbox();
    @include justify-content(center);
    width: 100%;
    background-color: $primary;

    .block-wrapper {
        @include flexbox();

        .block {
            @include flexbox();
            @include align-items(center);
            @include flex-direction(column);
            gap: 10px;
            width: 100%;
            padding: 20px 30px 30px;
            text-align: center;
            color: white;

            i {
                font-size: 26px;
            }

            span {
                font-size: 25px;
                font-weight: bold;
            }

            p {
                font-size: 18px;
                font-weight: 300;
            }

            &:nth-child(2) {
                background-color: $primary-light;
            }
        }
    }
}

.site-features {
    width: 100%;
    padding: 30px 20px 60px;

    .feature-wrapper {
        @include flexbox();
        @include flex-direction(column);
        gap: 80px;

        .feature {
            @include flexbox();
            @include flex-direction(column);

            &.revert {
                .description {
                    @include flex-direction(row-reverse);
                }

                h3 {
                    margin: 0 0 20px;
                }
            }
        }

        h3 {
            font-size: 28px;
            font-weight: bold;
            text-align: center;
            margin: 0 0 40px;
        }

        .description {
            @include flexbox();
            @include align-items(center);
            @include justify-content(space-between);
        }

        .text-wrapper {
            width: 50%;

            p {
                font-size: 20px;
            }

            button {
                margin: 20px 0 0;
            }
        }
    }
}

.site-image {
    @include flexbox();
    width: 100%;

    img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
}

.site-guidance {
    width: 100%;
    padding: 30px 0 40px;
    background-color: $primary;

    .guidance-wrapper {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
        width: 90%;
        margin: 0 auto;
        color: white;
        text-align: center;

        h3 {
            font-size: 28px;
            font-weight: 700;
        }

        p {
            font-size: 22px;
            margin: 20px 0;
        }
    }
}

@include breakpoint-max(md) {
    .main {
        background-image: url("../img/index_img_mobile.jpg");
    }

    .site-points {
        .block-wrapper {
            @include flex-direction(column);
        }
    }

    .site-features {
        .feature-wrapper {
            .description {
                @include flex-direction(column);
            }

            .feature {
                &.revert {
                    .description {
                        @include flex-direction(column-reverse);

                        p, .text-wrapper {
                            margin: 0;
                        }

                        img {
                            margin: 30px 0 0;
                        }
                    }
                }
            }

            .text-wrapper {
                width: 100%;
                margin: 20px 0 0;
            }
        }
    }

    .site-image {
        img {
            height: 100px;
        }
    }
}
