@import url('https://fonts.googleapis.com/css?family=Lato:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,900');

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
}

body {
    font-family: Lato, sans-serif;
    color: $font-color;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

span, a, button, p, li {
    font-family: Lato, sans-serif;
}

h1,h2,h3,h4,h5,h6,p {
    margin: 0;
}

h1 {
    font-size: 70px;
    color: white;
    font-weight: 600;
    line-height: 1.2;
}

h2 {
    font-size: 50px;
    font-weight: 500;
    line-height: 1.2;
}

h6 {
    font-size: 13px;
}

.btn {
    @include border-radius(5px);
    padding: 8px 24px;
    border: 1px solid white;
    background-color: transparent;
    cursor: pointer;
    font-weight: bold;
    color: white;
    text-decoration: none;

    &:hover,
    &:focus,
    &:focus-within,
    &:focus-visible,
    &:active {
        outline: 0;
        transition: filter 250ms, color 200ms;
        filter: saturate(1.4) brightness(80%);
    }
}

.btn-primary {
    color: white;
    border: 1px solid $primary;
    background-color: $primary;
}

.btn-secondary {
    color: $primary;
    background: white;
}

.container {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    padding: 0;
}

#header {
    .header-wrapper {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        position: relative;
        padding: 20px 0;
    }

    .logo {
        img {
            max-width: 250px;
        }
    }

    .sign-up {
        @include flexbox();

        .login {
            font-weight: 600;
            color: $primary;
            font-size: 13px;
            padding: 10px 15px;
            margin: 0 15px 0 0;
            cursor: pointer;
        }

        .register {
            @include border-radius(5px);
            padding: 10px 15px;
            background-color: white;
            font-weight: 600;
            color: $primary;
            border: 1px solid $primary;
            font-size: 13px;
            cursor: pointer;
        }
    }
}

footer {
    background-color: #fff;

    .footer-wrapper {
        @include flexbox();
        @include align-items(flex-end);
        @include justify-content(flex-start);
        padding: 30px 0 80px;
        gap: 250px;

        img {
            max-width: 170px;
            margin: 0 0 20px;
        }

        h4 {
            font-size: 24px;
            color: $font-color-footer;
            font-weight: 400;
            margin: 0 0 20px;
        }

        h6 {
            font-size: 14px;
            color: $font-color-footer;
            font-weight: 400;
        }

        a {
            font-size: 14px;
            color: $font-color-footer;
            padding: 7px;
            margin: 0 10px;
        }
    }
}

.modal {
    @include x-center();
    @include box-sizing(border-box);
    @include border-radius(30px);
    max-width: 500px;
    width: 100%;
    padding: 50px;
    background-color: #f5f5f5;
    z-index: 50;
    top: 150px;
    display: none;

    &.show {
        @include flexbox();
        @include flex-direction(column);
    }

    .title {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        margin: 0 0 30px;
    }

    .close {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;

        i {
            font-size: 26px;
            color: #7a7a7a;
        }
    }

    .form {
        margin: 0 auto 20px;

        input, select {
            @include border-radius(20px);
            @include box-sizing(border-box);
            border: 1px solid #d5d5d5;
            padding: 10px 20px;
            background-color: white;
            color: black;
            width: 100%;
            font-size: 18px;
            margin: 0 0 15px;

            &::placeholder {
                color: #ababab;
            }

            &.error {
                border-color: red;
            }
        }

        select {
            appearance: none;
            margin: 0;
        }

        .forgot-password {
            font-size: 16px;
            color: black;
            text-decoration: underline;
            margin: 10px 0 0 10px;
            cursor: pointer;
        }
    }

    .error-message {
        display: none;
        font-size: 13px;
        color: red;
        margin: -10px 0 20px 10px;
    }

    .success-message {
        display: none;
        font-size: 13px;
        font-weight: 500;
        color: #00c600;
        margin: -10px 0 20px 10px;
    }

    .button-wrapper {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(center);
        margin: 30px 0 0;

        button {
            padding: 10px 25px;
            background-color: $primary;
            color: white;
            border: 0;
            font-size: 13px;
            font-weight: 600;
            cursor: pointer;

            &.loading {
                position: relative;
                color: transparent;

                &:after {
                    @include xy-center();
                    content: "";
                    width: 16px;
                    height: 11px;
                    background-repeat: no-repeat;
                    background-image: url("../img/blocks_loader.gif");
                }
            }
        }

        p {
            display: none;
            font-size: 12px;
            font-weight: 500;
            color: #00c600;
            margin: 10px;

            &.error {
                color: red;
            }
        }
    }

    .terms {
        @include flexbox();
        @include flex-direction(column);
        margin: 20px 0 0;

        .term {
            @include flexbox();
            @include align-items(flex-start);
            margin: 0 0 15px;

            p {
                font-size: 10px;
                color: #737373;
                margin: 0 0 0 10px;

                &.error {
                    color: red;
                }
            }
        }
    }

    &#register {
        padding: 50px 50px 30px;
    }
}

.select-wrapper {
    position: relative;
    margin: 0 0 15px;

    &:after {
        @include y-center();
        content: "";
        display: block;
        right: 20px;
        width: 8px;
        height: 14px;
        background-size: 100%;
        background-image: url("../img/select_arrow.png");
    }
}

.background-overlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 30;
    background-color: rgba(0,0,0,0.5);
}

@include breakpoint-max(lg) {
    .container {
        max-width: 960px;
    }
}

@include breakpoint-max(md) {
    .container {
        max-width: 90%;
    }

    h1 {
        font-size: 42px;
    }

    body {
        #header {
            @include justify-content(space-between);
            padding: 15px;

            .logo {
                img {
                    max-width: 130px;
                }
            }
            .sign-up {
                position: relative;
                top: auto;
                transform: none;
                right: auto;

                .login {
                    padding: 6px 10px;
                    margin: 0 10px 0 0;
                }

                .register {
                    padding: 6px 10px;
                }
            }
        }

        footer {
            .footer-wrapper {
                @include align-items(center);
                @include flex-direction(column);
                text-align: center;
                gap: 0;
                padding: 30px 0 50px;

                h4 {
                    margin: 0 0 15px;
                }

                .menu {
                    @include flexbox();
                    @include flex-direction(column);
                    margin: 40px 0 0;
                }
            }
        }

        .modal {
            max-width: 90%;
            width: 90%;
            top: 50px;
            padding: 40px 20px;

            &#register {
                padding: 40px 20px;
            }

            .form {
                margin: 0 auto 10px;

                .select-wrapper {
                    margin: 0 0 10px;
                }

                select, input {
                    font-size: 14px;
                }

                .forgot-password {
                    display: table;
                    margin: 0 0 30px;
                }
            }

            .button-wrapper {
                margin: 0;
            }
        }
    }
}
